import 'react-image-lightbox/style.css';

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useCallback, useMemo, useState } from 'react';
import Lightbox from 'react-image-lightbox';

import * as styles from './Galeria.module.scss';

const GalleryView = () => {
  const [activePhotoIdx, setActivePhotoIdx] = useState<number>(0);
  const [openGallery, setOpenGallery] = useState(false);

  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: {
            extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
            relativeDirectory: { eq: "photos/gallery" }
          }
          sort: { fields: name }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    `,
  );

  const totalImages = query.images.edges.length;
  const nextPhotoIndex = useMemo(() => {
    const nextIndex = (activePhotoIdx || 0) + 1;
    return nextIndex > totalImages ? 0 : nextIndex;
  }, [totalImages, activePhotoIdx]);

  const prevPhotoIndex = useMemo(() => {
    const prevIndex = (activePhotoIdx || 0) - 1;
    return prevIndex < 0 ? totalImages - 1 : prevIndex;
  }, [totalImages, activePhotoIdx]);

  const handleNext = useCallback(() => {
    setActivePhotoIdx(nextPhotoIndex);
  }, [nextPhotoIndex]);

  const handlePrev = useCallback(() => {
    setActivePhotoIdx(prevPhotoIndex);
  }, [prevPhotoIndex]);

  const handleImageClick = useCallback(
    (idx) => () => {
      setActivePhotoIdx(idx);
      setOpenGallery(!openGallery);
    },
    [openGallery],
  );

  const getImageUrl = (idx: number): string => {
    if (idx >= totalImages) {
      return getImageUrl(0);
    }

    if (idx < 0) {
      return getImageUrl(totalImages - 1);
    }

    return query.images.edges[idx].node.childImageSharp.gatsbyImageData.images.fallback.src;
  };

  return (
    <section className={styles.root}>
      <h1 className={styles.header}>Galeria</h1>
      <p className={styles.description}>
        Nasz gabinet to miejsce jasne, przestronne i przyjazne dzieciom. Zapraszamy do zapozniania się z galerią zdjęć z
        naszego gabinetu.
      </p>

      <div className={styles.gallery}>
        {query.images.edges.map((image: any, idx: number) => (
          <GatsbyImage
            alt='image'
            image={getImage(image.node) as IGatsbyImageData}
            key={idx}
            className={styles.image}
            onClick={handleImageClick(idx)}
            objectFit='cover'
          />
        ))}
      </div>

      {openGallery && (
        <Lightbox
          mainSrc={getImageUrl(activePhotoIdx)}
          nextSrc={getImageUrl(nextPhotoIndex)}
          prevSrc={getImageUrl(prevPhotoIndex)}
          onCloseRequest={() => setOpenGallery(false)}
          onMovePrevRequest={handlePrev}
          onMoveNextRequest={handleNext}
        />
      )}
    </section>
  );
};

export default GalleryView;
